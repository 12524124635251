#newitem-area {
  user-select: none;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, #000000, #ffffff14),
    urL("../../../../public/images/banner/main_2.jpg") no-repeat fixed center / cover;

  &::before {
    backdrop-filter: blur(4.3px) grayscale(0.7) saturate(1) brightness(2);
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    bottom: 0;
  }
  padding-bottom: 30px;
  .swiper-button-prev,
  .swiper-button-next {
    padding: 0px 0px;
    color: #00000045;
    filter: drop-shadow(0px 0px);
    translate: 0 150%;
    transform: scaleY(0.7) scaleX(0.6);
  }
  .swiper-3d .swiper-slide-shadow-right,
  .swiper-3d .swiper-slide-shadow-left {
    background: none;
    //  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
  .swiper-3d {
    perspective: 2000px;

    .swiper-pagination-bullet-active {
      background: antiquewhite;
    }
  }
}
.newitem-area {
  position: relative;
  width: 80%;
  .swiper-slide.swiper-slide {
    overflow: hidden;
    backdrop-filter: invert(0.9) brightness(5) grayscale(1);
    filter: drop-shadow(2px 4px 6px #444);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin: 50px 0;
    /* width: 80%; */
    background: var(--bg-color1);
    padding-bottom: 150px;
    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center center;
    }
    .wrap {
      position: absolute;
      width: calc(100% - 20px);

      button.item {
        // top: 118px;
      }
    }
  }
}

.bestitem-area {
  user-select: none;
  h2.etit {
    padding-top: 10px;
    font-family: "Cormorant Garamond", serif;
    font-size: 20px;
  }
  h4.price {
    font-family: "Cormorant Garamond", serif;
    padding: 10px 0;
  }

  h3.ktit {
    white-space: pre-wrap;
    padding-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  p.rev {
    display: inline;
    font-size: 12px;
  }
  span.rev2 {
    font-size: 13px;
    color: #522;
    padding: 0 5px;
  }
  button.item {
    background-color: white;
    font-style: italic;
    translate: -50%;
    left: 50%;
    position: absolute;
    display: block;
    border: 1px solid;
    padding: 5px 0px;
    width: 50%;
  }

  h3.catag {
    text-align: center;
    font-family: "Cormorant Garamond", serif;
    font-size: min(27px, 5vw);
    font-weight: 500;
  }

  position: relative;
  width: 80%;

  h3.catag {
    padding-top: 20px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    filter: drop-shadow(2px 2px 4px #777);
  }

  .swiper-slide.swiper-slide {
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin: 80px 0;
    width: 80%;
    // background-color: #f7f7f7;
    // border: 1px solid #999;
    /* box-shadow: 1px 1px 6px 0px #c1c1c1; */
    padding-bottom: 150px;


   &>a {
      overflow: hidden;
      display: block;
  }

    img {
      transition: 0.2s;
      width: 100%;
      scale: 1.15;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center center;
      overflow: hidden;
      &:hover {
    rotate: 10deg;
    scale: 1.1;
      }
      
    }
    .wrap {
      text-align: center;
      position: absolute;
      width: calc(100% - 20px);
      h2.etit {
        padding-bottom: 15px;
      }

      button {
        padding: 0 5px;
        margin-top: 2.5vw;
        // margin-top: 30px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 0px;
        height: 40px;
        top: 130px;
      }
    }
  }
}

.bestitem-area.inshop {
  width: 100%;
}

@media screen and (max-width: 900px) {
  #newitem-area button.item {
    translate: -0%;
  }
}
@media screen and (max-width: 600px) {
  section.newitem-area.inbox.swiper.mySwiper2 {
    width: min(322px, 80vw);
  }
  .wrap {
    h2.etit {
      font-size: 4.5vw;
    }
    h4.price {
      font-size: 3.5vw;
    }

    h3.ktit {
      font-size: min(13px, 2.3vw);
    }
  }
  .bestitem-area {
    width: 100%;
  }
}
