// 콘텐츠 모듈 SCSS - _content.scss

@for $i from 1 through 4 {
  // 1부터 4까지 반복
  .collection#{$i} {
    // #{$i}는 for문의 반복값, #{}는 변수값을 가져온다.
    background-image: url(../../public/images/collection_#{$i}.jpg);
  }
  .story#{$i} {
    background-image: url(../../public/images/story_#{$i}.jpg);
  }
}

.content-area {
  .col-12 {
    display: flex;
  }
}

#content-area {
  padding-bottom: 10px;
  background: var(--bg-color1);
  .wrap {
    //스티키부모상자
    /* 영역 비율적용 */
    flex: 1;
  }
  .textbox-wrap {
    //스티키 대상
    top: 100px;
    position: sticky;
    padding: 15px;
    span.stit {
      display: inline-block;
      width: min(200px, 13vw);
      border-bottom: 1px solid #777;
    }
    small {
      position: relative;
      display: inline-block;
      margin-bottom: 20px;
      font-size: clamp(10px, 3vw, 13px);
      font: {
        family: "Cormorant Garamond", serif;
        style: italic;
        weight: bold;
      }
    }
    h2 {
      /* block이지만 인라인블록처럼 */
      width: min-content;
      color: #000;
      font-size: clamp(14px, 7vw, 9vw);
      font-family: "Cormorant Garamond", serif;
      padding-bottom: 20px;
    }
    span.text {
      text-align: justify;
      line-height: 1.5;
      display: block;
      width: 60%;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
    }
    button {
      margin: 10px 0;
      margin-top: 40px;
      display: block;
      width: auto;
      border-radius: 100px;
      border: 1px solid #6e6e6e;
      padding: 14px;
      font-size: 15px;
      font-family: "Open Sans", sans-serif;
      background-color: #fff;
      font-weight: 500;
    }
  }

  .imgbox-wrap {
    position: relative;
    width: 50%;

    .img-wrap {
      background-color: black;
      padding: 40px;
      box-sizing: border-box;
      width: 100%;
      a {
        border-radius: 20px;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        position: relative;
        display: block;

        /* 배경이미지 */
        background-size: 100%;
        background-position: center;
        transition: 0.3s ease-out;

        &:hover {
          background-size: 110%;
        }
        /* 기본비율. story일시 jsx에서 비율 덮어적용 */
        aspect-ratio: 1/1;
        // img {
        //    width: 100%;
        //    aspect-ratio: 1/1;
        // }
        .desc {
          position: absolute;
          top: 100%;
          left: 0;
          translate: 0 -100%;
          color: #fff;
          font-size: clamp(14px, 8vw, 14vw);
          font-weight: 500;
          font-family: "Cormorant Garamond", serif;
          text-shadow: 0px 2px 2px #000, 0 0 5px #fff;
        }
      }
    }
  }
}

#content-area .content-area.story {
  .col-12 {
    flex-direction: row-reverse;

    .img-wrap a {
      aspect-ratio: 732/378;
    }

    .textbox-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .imgbox-wrap {
      small {
        display: block;
        font-size: min(12px, 3vw);
        font-family: "Open Sans", sans-serif;
        padding: 0 15px;
        padding-bottom: 5px;
        margin-top: -5px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #content-area .content-area {
    .img-wrap a {
      border: 0px solid black;
      box-sizing: border-box;
      &:hover {
        border: 20px solid black;
        box-sizing: border-box;
      }
    }
    &.story {
      .col-12 {
        flex-direction: column;

        .img-wrap {
          overflow: hidden;
          
        padding-bottom: 10px;

          a {
            aspect-ratio: 1 / 1;
            background-size: 200%;
            border: 0px solid black;
            box-sizing: border-box;
            &:hover {
              border: 20px solid black;
              box-sizing: border-box;
              background-size: 220%;
              background-position: 60% 100%;
              transition: 0.5s ease-in-out;
            }
            background-position: 28% 70%;
            .desc {
              translate: 0 -120%;
            }
          }
        }
      }
    }
  }

  .content-area .col-12 {
    display: flex;
    flex-direction: column;
  }
  #content-area {
    .imgbox-wrap {
      width: 100%;
      .img-wrap {
        background-color: transparent;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        & a {
          flex-basis: 50%;
          margin-bottom: 0px;
          border-radius: 0px;
          & .desc {
            padding-left: 10px;
            text-shadow: 0px 2px 2px #000;
          }
        }
      }
    }
    .textbox-wrap h2 {
      font-size: min(90px, 14vw);
      span.text {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  #content-area {
    .textbox-wrap span.text {
      width: 90%;
    }
  }
}
