/* 회원가입 페이지 CSS */

/* 최상위 부모박스 */
.outbx {
  padding: 25px;
}
/* 전체박스 */
.membx {

  a.gest {
    font-size: small;
    text-align: center;
    font-weight: 700;
    display: none;
}

  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(10px) sepia(1);
  background: #ffffffcf;
  max-width: 700px;
  min-height: 600px;
  padding: 15px;
  border: 1px solid #555;
  margin: 0 auto;
  font-family: "Ubuntu Condensed", sans-serif;
  h2 {
    color: white;
    background: black;
    font-weight: bold;
    font-family: "Cormorant Garamond", serif;
    text-align: center;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 45px;
  }

  li {
    position: relative;
    padding: 2vw 3vw;
    font-family: math;
    &.btnBox {
      padding-top: 10px;
      text-align: center;
    }
  }
  label {
    text-align-last: left;
    display: inline-block;
    width: 25%;
    text-align: right;
    font-size: 20px;
    white-space: nowrap;
  }
  input {
    width: 60%;
    height: 3vh;
    margin-left: 5%;
    font-size: 15px;
    vertical-align: baseline;
    font-family: "Ubuntu Condensed", sans-serif;
  }
  .msg {
    position: absolute;
    left: 31%;
    width: 50%;
    font-size: 14px;
    /* outline: 1px dotted red; */
    padding: 5px 0;
    font-weight: bold;
  }

  .sbtn {
    margin: 0 1vw;
    white-space: nowrap;
    font-size: min(20px, 3vw);
    background-color: white;
    border-radius: 0px;
    width: 150px;
    padding: 7px;
    border: 1px solid black;
    &.loginBtn {
      box-sizing: border-box;
      filter: invert(1);
      position: relative;
      display: inline-block;
      border: 1px solid white;
      a.loginLink {
        color: transparent;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        display: inline-block;
      }
    }

  }

  ul {
    .txt {
      padding: 0 15vw;
      padding-bottom: 0px;
      text-align: end;
    }
  }

  small {
    font-size: 11px;
    color: red;
    font-weight: 600;
  }
}
li.btnBox {
  display: flex;
  justify-content: center;
}
.txt {
  white-space: nowrap;
  padding: 0 15vw;
  box-sizing: border-box;
  text-align: end;
  margin-left: auto;
  display: block;
  flex: none;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .membx input {
    display: block;
    width: 100%;
    margin-left: auto;
  }
  .membx li {
    box-sizing: border-box;
    margin: 0 auto;
    width: 70%;
  }
  .membx label {
    padding-bottom: 2vw;
  }
}
@media screen and (max-width: 550px) {
  .membx li {
    
    padding-bottom: 20px;
    width: 100%;
  }
}
