/* @charset "utf-8";*/
/* 펜할리곤스 PJ 공통핵심 CSS - core.scss */
/* 웹폰트, 공용클래스 */

/*********** 1. 웹폰트 ***********/

/*Cormorant Garamond 체 + Open Sans체 -CDN  */
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
/*font-family: 'Cormorant Garamond', serif;*/
/*font-family: 'Open Sans', sans-serif;*/

/* 나눔바른고딕 */
@font-face {
  font-family: nbg;
  src: url(../../static/fonts/etc/NanumBarunGothicLightSubset.woff)
    format("woff");
}

/* Pistilli체 */
@font-face {
  font-family: pist;
  src: url(../../static/fonts/etc/Pistilli-Roman.woff) format("woff");
}

/* 
  [ 공통 클래스 이름정의 ]
  1. inbox - inside box 속박스
  2. pt1, pt2 - part1, part2 각 부분박스(비율적용)
  3. cont-box - content box 내용박스 (flex박스)
  4. bgi - background image 관련 속성

[시멘틱 구조예시]
<header>
    <nav>
<main>
    <section>
        <article>
        <aside>
        <figure><figcaption>
<footer>
    <address>
    
[영역 구조 예시]
<div id="some-area">
  <section class="some-area inbox pt2">
    <h2 class="temp-tit">임시이름</h2>
    <div class="cont-box">
        <div class="col-6"></div> 
        <div class="col-12"></div> 
    </div>
  </section>
</div> 

*/

/* 공사중 표시 */
body * {
// outline: 1px dashed rgb(205, 205, 205);
  cursor: none;
 
}


/************* 2. 공통 class *************/

/* 임시 표시타이틀 */
.temp-tit {
  opacity: 0.2;
  left: 0;
  position: absolute;
  font-size: 2vw;
  background-color: grey;
  color: #ccc;
  z-index: -1;
  display: none;
}

/* 2-1. 중앙 속박스 */
.inbox {
  padding: 0 10px;
  max-width: 1800px;
  margin: 0 auto;
}

/*********** 2-2. 컨텐츠 박스 유형별 클래스 ***********/
/* 유형1 - 높이 비율유지 1개박스구성 : .pt1 */
.pt1 {
  /* 비율 적용시 height값은 주지않는다! */

  /* 비율 */
  aspect-ratio: 1800 / 700;
}
/* 유형1 컨텐츠박스 */
.pt1 .cont-box {
  /* 부모박스 높이값에 맞춤! */
  height: 100%;
}

/* 유형2 - 높이 비율유지 다중박스구성 : .pt2 */
.pt2 {
  /* 플렉스박스 */
  display: flex;
  /* 초기방향 (필요시바꾸기) */
  flex-direction: column;

  /* 비율 적용시 height값은 주지않는다! */

  /* 비율 */
  aspect-ratio: 1300 / 700;
}
/* 유형2 컨텐츠박스 : 등분할 가로정렬박스 */
.pt2 .cont-box {
  /* 플렉스 자식요소 동일분할 설정!
    -> 높이값 100% 설정불필요! 자동맞춤된다! */
  flex: 1;
}

/* ********************************************* */
/* 2-3. 배경이미지 공통셋팅 클래스 */
.bgi {
  background-repeat: no-repeat;
  background-size: cover;
}

/* 컨텐트 박스 
-> 플렉스 박스는 싸고 있는 부모를 설정함! flex의 자식요소는 자동으로
    부모를 채우도록 설정되어 있다! */
.cont-box {
  display: flex;
  height: 100%;
  /* 부모자격 */
  position: relative;
}

/* 가상요소: 영역을 채우고 호버시 동적효과 */
.any::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000000;
  /* cursor: pointer;*/

  /* 트랜지션: 속시이지 */
  transition: border 0.2s ease-in-out;
}
/* .cont-box에 오버시 */
.any:hover::after {
  background-color: #00000000;
}

/* 4. 스크롤 등장액션 클래스 */
.scAct {
  transform: translateY(20%);
  opacity: 0;
  transition: 1.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
/* 등장클래스+.on */
.scAct.on {
  transform: translateY(0);
  opacity: 1;
}

/* 글자 숨김 클래스(IR배경이미지 설명 등  숨기기) */
.ir {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

/* 공통배경색 클래스 */
.bgc {
  background-color: #f9f9f9;
}

/* 화면출력 숨기기 클래스 */
.hidden {
  display: none;
}
.show {
  display: block;
}

/* 스크롤바 디자인 클래스 */
/* -webkit- 밴더사명(크롬,사파리) 접두어 */
/* 전체 스크롤바 클래스명 scbar */

.scbar::-webkit-scrollbar {
  /* 전체 스크롤바 크기변경 */
  width: 12px;
}
.scbar::-webkit-scrollbar-thumb {
  /* 스크롤바 */
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid white;
}
.scbar::-webkit-scrollbar-track {
  /* 스크롤바 트랙 */
  background-color: transparent;
  border-radius: 5px;
}

/* 부분 스크롤바 클래스명 scbar */
.subbar::-webkit-scrollbar {
  /* 전체 스크롤바 크기변경 */
  width: 8px;
}
.subbar::-webkit-scrollbar-thumb {
  /* 스크롤바 */
  background-color: rgba(113, 38, 228, 0.6117647059);
  border-radius: 5px;
}
.subbar::-webkit-scrollbar-track {
  /* 스크롤바 트랙 */
  background-color: transparent;
  border-radius: 5px;
}



/* 버튼 공통스타일 */

main.cont button {
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-out;
  z-index: 1;

  &::after {
    background-color: black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    display: block;
    transition: transform 0.2s ease-out, opacity 0.6s 0s;
    transform: scaleX(0) skewX(20deg);
    z-index: -1;
    opacity: 0;
  }
  &:hover {
    color: #fff;

    &::after {
      opacity: 1;
    transform: scaleX(1.2) skewX(20deg);
    }
  }
}

:root {
--bg-color1: linear-gradient(45deg, #f9f9f9 38%, transparent)
}

@media screen and (max-width: 700px) {
body *{
  cursor: auto;
}
  
}