// 타이틀 모듈 SCSS - _title.scss

#newitem-area {
  .tit-wrap {
    text-shadow: 2px 2px black;
    color: antiquewhite;
  }
  h3.catag {
    text-shadow: 2px 2px black;
    color: antiquewhite;
  }
}

.tit-wrap {
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
  h4 {
    font-family: "Open Sans", sans-serif;
    font-size: min(20px, 5vw);
    font-weight: 400;
    font-style: italic;
  }
  h2.tit {
    text-align: center;
    font-family: "Cormorant Garamond", serif;

    padding-bottom: min(5px, 2vw);
    font-size: min(55px, 9vw);
  }

  h3.catag {
    text-align: center;
    font-family: "Cormorant Garamond", serif;
    font-size: min(22px, 2vw);
  }
  span.tit {
    line-height: 1.2;
    font-size: min(16px, 4vw);
  }
}
