.nodata {
  padding-top: 20px;
  min-height: 50vh;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

h2.etit {
  padding-top: 10px;
  font-family: "Cormorant Garamond", serif;
  font-size: min(20px, 2.2vw);
}
h4.price {
  padding: 10px 0;
}

h3.ktit {
  // white-space: nowrap;
  padding-bottom: 1vw;
  font-family: "Open Sans", sans-serif;
  font-size: min(13px, 1.3vw);
  font-weight: 600;
}
p.rev {
  display: inline;
  font-size: 12px;
}
span.rev2 {
  font-size: 13px;
  color: #522;
  padding: 0 5px;
}
button.item {
  margin: 0 auto;
  translate: 0% -20%;
  margin-top: min(10px, 1.5vw);
  background-color: white;
  font-style: italic;
  display: block;
  border: 1px solid;
  padding: 5px 0px;
  width: 100%;
}

h3.catag {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-size: min(27px, 5vw);
  font-weight: 500;
}

.product-box {
  gap: 1vw;
  padding: 0;
  margin: 50px auto;
  flex-wrap: wrap;

  justify-content: center;
  display: flex;
  > li {
    box-shadow: 1px 2px 5px gainsboro;
    box-sizing: border-box;
    padding: 0.7vw;
    display: flex;
    flex-basis: calc(25% - 1vw);
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 5px;
    &:hover {
      // border: 1px solid black;
    }
    > a {
    }
    .item-wrap {
    }
    img {
      mix-blend-mode: darken;
      vertical-align: middle;
      object-fit: cover;
      width: 100%;
      transition: 0.2s;
      &:hover {
        rotate: 10deg;
        scale: 1.2;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .product-box {
    > li {
      flex-basis: calc(100% / 3 - 1vw);
    }
  }
}
@media screen and (max-width: 1050px) {
  .product-box {
    > li {
      flex-basis: calc(100% / 2 - 1vw);
    }
  }
}
@media screen and (max-width: 768px) {
  .product-box {
    > li {
      flex-basis: calc(100% / 4 - 1vw);
    }
    h2.etit {
      display: none;
    }
  }
}
@media screen and (max-width: 735px) {
  .product-box {
    > li {
      flex-basis: calc(100% / 3 - 1vw);
    }
  }
}
@media screen and (max-width: 620px) {
  .product-box {
  }
}

.pasing {
  text-align: center;
  padding: 20px 0;
}
.totalcount {
    text-align: center;
    font-size: clamp(20px, 4vw, 40px);
    font-weight: bold;
    padding-bottom: 30px;
}