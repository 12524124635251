// search inner 컴포넌트 - _search_Inner.scss

#search-area
  .search-area
  .cont-box
  .searching-box
  .list-wrap
  > ul
  > li
  > ol
  > li
  ol
  > li {
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  list-style: initial;
}
.search-area {
  .cont-box {
    .page {
      flex: none;
      // margin-left: auto;
      > ul {
        padding: 20px 0px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        a {
          padding-right: 5px;
          &.on{
            font-weight: bold;
          }
        }
      }
    }
    // flex-wrap: wrap;
    min-height: 100vh;
    .searching-box {
      .input-box {
        margin: 10px;
        display: flex;
        align-items: center;
        max-width: max(250px);
        box-sizing: border-box;
        background-color: white;
        filter: drop-shadow(2px 2px 2px rgb(184, 184, 184));
        padding: 10px 15px;
        border-radius: 100px;
        input {
          color: #777;
          font-size: 15px;
          background-color: initial;
          border: none;
          margin-left: 10px;
        }
      }
      .list-wrap {
        font-family: "Cormorant Garamond", serif;
        top: 75px;
        position: sticky;
        line-height: 1.5;
        padding: 5px;
        border: 1px groove;
        margin: 20px;
        h2 {
          background: black;
          color: #fff;
          text-align: -webkit-center;
          margin-bottom: 10px;
          line-height: 1.7;
          font-size: 20px;
          font-weight: bold;
        }
        ul > li {
          font-size: 20px;
          font-weight: bold;
          padding: 0 5px;
          position: relative;
          &:first-child > span {
            font-size: 19px;
          }

          span {
            color: navy;
            font-size: 15px;
            padding: 3px;
            font-family: math;
            font-weight: 600;
          }

          .checkbox {
            opacity: 0;
            pointer-events: none;
            &:checked + label.label::after {
              opacity: 1;
            }
          }
          label.label {
            position: absolute;
            left: 100%;
            translate: calc(-100% - 5px) 5px;
            display: inline-block;
            width: 20px;
            height: 20px;

            background: #bdbdbd;
            &::after {
              pointer-events: none;
              opacity: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              translate: -50% -50%;
              display: inline-block;
              content: "✔";
              color: #fff;
            }
          }
          ol {
            padding: 15px;
            padding-left: 20px;
            & > li {
              font-size: 17px;
              font-weight: 600;
              &.last {
                padding-top: 5px;
                font-weight: 800;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .result-box {
      .wrap {
        font-family: "Cormorant Garamond", serif;
        line-height: 1.5;
        padding: 10px 0;
        text-align: center;
        > h2 {
          padding-top: 70px;
          font-size: 25px;
          font-weight: 600;
          strong {
            font-family: "Open Sans", sans-serif;
            font-size: 30px;
            font-weight: 600;
          }
        }
        h3 {
          font-size: 20px;
          font-weight: 400;
          color: #1e1e1e;
        }
      }
      .sortbx select {
        position: relative;
    
      }
      /* 아직없는 상품뿌리기박스 */
    }
  }
}

@media screen and (max-width: 768px) {
  #search-area .search-area .cont-box .searching-box .list-wrap ul > li ol {
    padding: 0px;
  }
  #search-area .search-area {
    .cont-box {
      flex-direction: column;
      .searching-box {
        border-radius: 10px;
        box-shadow: 0px 5px 15px #66666629;
        background-color: white;
        top: 0px;
        position: sticky;
        z-index: 2;
        .list-wrap {
          font-family: "Cormorant Garamond", serif;
          > ul {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            &.on {
              height: 0;
            }
            > li {
              &:nth-child(n + 1):nth-child(-n + 4) > label {
                /* 대메뉴라벨 : 컬렉션/아워스토리/프로덕트 */
                opacity: 1;
              }
              > ol {
                text-align: right;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                & > li {
                  position: relative;
                  white-space: nowrap;
                  padding: 0 5px;
                  flex: 1 1;
                  flex-basis: calc(25% - 10px);
                  &:last-child {
                    overflow: hidden;
                    flex-basis: 100%;

                    > label {
                      /* 컬렉션 라벨 */
                      top: 0%;
                      translate: -100% 5px;
                      // background-color: red;
                    }
                  }
                  > label {
                    top: 50%;
                    translate: -100% -50%;
                  }
                  &:nth-child(n + 1):nth-child(-n + 4) {
                    > label {
                      /* 프로덕트 서브 라벨  */
                      opacity: 1;
                    }
                  }
                  ol {
                    padding: 5px 0;
                    height: 0px;
                    display: flex;
                    overflow: hidden;
                    // height: 100px;
                    align-items: center;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    transition: 0.2s;
                    &.on {
                      height: 100px;
                    }
                    label {
                      translate: -100% 30%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
