/*@charset "utf-8";*/ 
/* 펜할리곤스 PJ 리셋 CSS - reset.scss */
/* 요소의 초기화 */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a{
    text-decoration: none;
color: #000; 
}




// /* 1. 마진,패딩 없애기 */
// html,body,h1,h2,h3,h4,h5,h6,ul,ol,dt,dd,p{
//     margin: 0;
//     padding: 0;
// }

// /* 가로스크롤 생김 방지!(특히 모바일) */
// html, body{
//     // overflow-x: hidden;
// }

// /* 2. a요소 리셋 */
// a{
//     color: #000;
//     /* 글자색 */
//     text-decoration: none;
//     /* 밑줄없앰 */
// }

// /* 3. ul,ol 리셋 */
// ul,ol{
//     list-style: none;
//     /* 블릿/숫자 제거 */
// }

// /* 4. h요소 글자크기 리셋 */
// h1,h2,h3,h4,h5,h6{
//     font-size: 100%;
//     /* html의 기본글자크기(16px)와 일치 초기화 */
// }

// /* 5. 이미지 리셋 */
// img {
//     /* 나중에 문제되면 빼기 */
//     /*width: 100%;*/ 
//     border: none;
//     /* 이미지 링크시 서버에서 볼때 파란색 자동보더 없애기 */
//     vertical-align: bottom;
//     /* 박스 이미지 하단간극 없애기(약4px)
//     값으로 top, middle등 도 효과있음! */
// }