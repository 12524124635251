#shop-area > section.shop-area.inbox {

  select {
    display: block;
    margin-left: auto;
    font-size: 17px;
    border: 2px solid;
  }
  .tit-wrap:nth-of-type(2) {
    padding: 0 2vw;
    padding-top: min(150px, 20vw);
    padding-bottom: min(80px, 10vw);
    .tit {
      text-align: left;
      display: block;
    }
  }
  > .cont-box {
    display: block;
  }
  ul.map {
    display: flex;
    position: relative;
    translate: 0 300%;

    font-size: clamp(min(16px, 5vw), 2vw, 20px);
    button {
      padding: 0px;

      font-size: clamp(min(16px, 5vw), 2vw, 20px);
      border: none;
      background: transparent;
      &:hover {
        color: #000;
      }
      &::after {
        display: none;
      }
    }
  }



}//////////////////////////////
.shop-img {
  // border-radius: 50%;
  width: 100%;
  height: 50vw;
  span.smenu-tit {
    text-shadow: 2px 2px 10px #000000ab;
    text-align: center;
    font-weight: 600;
    font-family: "Cormorant Garamond", serif;
    position: relative;
    top: 50%;
    translate: 0 -50%;
    display: block;
    font-size: 13vw;
    color: #fff;

    > p {
      font-size: min(150px, 10vw);
    }
  }
}

ul.smenu-box {
  font-size: 20px;
  font-weight: 500;
  font-family: "Cormorant Garamond", serif;
  padding-top: 100px;
  text-align: center;
  display: flex;
  li {
    box-sizing: border-box;
    padding: 0px 30px;
    flex: 1;
    a {
      white-space: nowrap;
      font-size: clamp(15px, 2vw, 4vw);
    }
  }
}

ol.submenu {
  /* 폰트크기와 통일 */
  height: clamp(20px, 2vw, 4vw);
  background: black;
  margin-top: 20px;
  transition: 0.3s;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  a {
    color: white;
  }
}

p.smenu-tit {
  font-weight: 400;
  font-size: clamp(30px, 3vw, 10vw);
  font-family: "Cormorant Garamond", serif;
  padding: 50px;
  text-align: center;
}

.swiper {
  box-sizing: border-box;
}

.more {
  font-family: "Open Sans", sans-serif;
  border: none;
  background: none;
  font-weight: unset;
  font-size: initial;
}



section.bestitem-area.inshop.inbox.swiper.mySwiper2>.catag.shop {
  
  // background: antiquewhite;
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: min(35px, 7vw);
}

@media screen and (max-width: 800px) {
  ul.smenu-box {
    flex-direction: column;
    li {
      padding: 0px;

      a {
        line-height: 1.5;
        font-size: clamp(20px, 2vw, 4vw);
      }
    }

    ol.submenu {
      margin-top: 0px;
      height: 0px;
      margin-bottom: 20px;
      position: relative;
      flex-direction: column;
    }
  
    .coll {
      transition: 0.2s;
      filter: invert(1);
      display: block;
      position: relative;
      width: 100%;
      background: white;
    }
  }

  .shop-img {
    border-radius: 0%;
  }
  .tit-wrap span.tit {
    font-size: min(21px, 5vw);
  }
}
