// 콘텐츠 이너 모듈 SCSS - _inner.scss

.textbox-wrap {
  top: 100px;
  padding: 15px;
  .delete {
    font-family: "Open Sans", sans-serif;
    font-weight: bolder;
    display: inline-block;
    transform: scaleY(0.8);
    scale: 1.4;
    position: relative;
    &.item {
      translate: -91% -162%;
    }
    &.gift {
      padding: 0 10px;
      right: 0;
      position: absolute;
    }
  }
  span.stit {
    display: inline-block;
    width: min(200px, 13vw);
    border-bottom: 1px solid #777;
  }
  small {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    font-size: clamp(10px, 3vw, 15px);
    font: {
      family: "Cormorant Garamond", serif;
      style: italic;
      weight: bold;
    }
  }
  h2 {
    /* block이지만 인라인블록처럼 */
    width: min-content;
    color: #000;
    font-size: clamp(14px, 7vw, 9vw);
    font-family: "Cormorant Garamond", serif;
    padding-bottom: 20px;
  }
  span.text {
    text-align: justify;
    line-height: 1.5;
    display: block;
    width: min(327px, 34vw);
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
  button {
    display: block;
    width: auto;
    border-radius: 100px;
    border: 1px solid #6e6e6e;
    padding: 14px;
    margin: 20px 0;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    font-weight: 500;
  }
  .opt-wrap {
    &.next {
      margin: 0 0px;
      margin-bottom: 10px;
    }
    .item-name {
      position: relative;
      font-size: 12px;
      width: 80%;
      display: inline-table;
    }

    // [ 첫번쨰옵션 ]
    &.first {
      overflow: hidden;
      border-bottom: none;
      height: 0px;
      &.open {
        // height: 75px;
        height: fit-content;

      }
    }
    // [ 두번쨰옵션 ]
    &.next {
      overflow: hidden;
      height: 0px;
      &.open {
         height: 55px;
       }
    }
    // height: 0px;
    // height: 55px;
    transition: 0.2s;
    margin-top: 20px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    // padding: 10px 0px;

    .opt-box {
      position: relative;
      display: flex;
      padding: 5px 0px;
      align-items: center;

      .price-wrap {
        &.gift {
          padding: 0px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          span.gift-tit {
            font-size: small;
            font-weight: 600;
            padding: 5px 0;
            padding-right: 20px;
            font-family: "Open Sans", sans-serif;
            span.delete.gift {
              position: absolute;
              left: 100%;
              translate: -280%;
            }
          }
        }

        span.gift-info {
          font-size: 10px;
        }
        margin-left: auto;
        .result {
          font-weight: bold;
          padding: 0 20px;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  section.main-wrap #ItemDetail-area .ItemDetail .cont-box {
    .col-4 {
      .textbox-wrap small {
        margin-bottom: auto;
      }
      .btn-wrap {
        display: flex;
        box-sizing: border-box;
        button.item-button {
          margin: 1vw;
        }
      }

      select.option {
        width: 105px;
        margin: 10px 10px;
        display: inline-block;
      }

      .reviews span.star-box {
        position: absolute;
        left: 100%;
        translate: -100% 30%;
      }
    }
    .left-box {
      margin-top: -550px;
      width: auto;
    }
    svg {
      padding: 1.5vw;
      top: 0;
      translate: 0 175%;
    }
    .tit-bx {
      padding-bottom: 5px;
    }
  }

  .textbox-wrap .opt-wrap .opt-box .price-wrap.gift {
   
   text-align: center;
    padding: 0px 25px;
    span.gift-tit {
      font-size: min(20px ,4vw);
      padding-right: 0px;
    }
  }
  .textbox-wrap .opt-wrap .opt-box .price-wrap span.gift-info {
   font-size: 12px;
}
}

main button.up.detail,
main button.down.detail {
  margin: 0;
  padding: revert-layer;
  border-radius: 0;
  background-color: #00000008;
}

main .btn-box .up.detail::after {
  transition: none;
  width: 10px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1;
}

main.cont button.detail:hover::after {
  transition: none;
  transform: scaleX(1) skewX(0deg);
  translate: -50%;
}
main.cont .btn-box .down::after {
  transition: none;
}
