/* Opinion 게시판 첨부파일 관련 CSS */
.icon {
    width: 100px;
    height: 100px;
    pointer-events: none;
 }
 
 .file {
    display: none;
 }
 
 .file::file-selector-button {
    font-size: 14px;
    background: #fff;
    border: 1px solid #111;
    border-radius: 12px;
    padding: 4px 32px;
    cursor: pointer;
 }
 
 .info-view {
    max-width: 300px;
    height: 120px;
    /* margin: auto; */
    background-color: #fff;
    border-radius: 5px;
    border: 5px dotted #999;
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }
 
 .info-view:hover {
    border-color: pink;
 }
 
 .info-view-msg {
    font-weight: 500;
    font-size: 18px;
    margin: 20px 0 10px;
 }
 
 .info-view-desc {
    margin: 0;
    font-size: 14px;
 }
 
 .info-view.on {
    background-color: #efeef3;
    border-color: #111;
 }
 
 .info-view-info {
    width: 100%;
    list-style: none;
    padding: 0;
    gap: 5px;
    display: flex;
    flex-direction: column;
 }
 
 .info-view-info .info-key {
    display: inline-block;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 4px;
 }
 
 .info-view-info .info-value {
    font-size: 20px;
    margin-left: 20px;
 }
 