// 서브 디테일 모듈 SCSS - _sub_detail.scss

#sub-detail-area {
  .sub-detail-area {

    strong {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: smaller;
    }

    max-width: 900px;
    /* 제목내용 */
    .txt-wrap {
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 20px;
      text-align: center;
      button {
        display: block;
        background-color: white;
        border: 1px solid black;
        margin: 0 auto;
        padding: 10px;
        margin-top: 10px;
      }
      &.only {
        padding-top: 0px;
      }
      h2 {
        text-align: center;
        font-family: "Cormorant Garamond", serif;

        padding-bottom: min(20px, 2vw);

        font-size: min(48px, 9vw);
      }
      span {
        font-family: "Open Sans", sans-serif;
        line-height: 1.5;
        font-size: min(16px, 4vw);
      }
    }
    /* 이미지+글 */
    .img-wrap {
      padding-top: 50px;
      text-align: center;
      margin: 0 auto;
      h2 {
        text-align: center;
        font-family: "Cormorant Garamond", serif;

        padding-bottom: min(20px, 2vw);

        font-size: min(48px, 9vw);
      }
      img {
        width: 100%;
        padding-bottom: 20px;
      }
      span {
        font-family: "Open Sans", sans-serif;
        line-height: 1.5;
        font-size: min(16px, 4vw);
      }
    }
    /* 분활상자 */
    .content-box {
      padding-top: 50px;
      display: flex;
      .img-wrap {
        flex-basis: 50%;
        padding: 0px;
        img {
          padding: 0px;
          height: 100%;
          object-fit: cover;
        }
      }
      .txt-wrap {
        flex-basis: 50%;
        padding: 15px;
        text-align: justify;

        background: var(--bg-color1);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    } /* .content-box */
  } /* .sub-detail-area */
} /* #sub-detail-area */
