// 마이페이지 SCSS - _my_page.scss

.mypage-area {
  .col-9 .next h2.cart-tit {
    font-size: clamp(22px, 3vw, 30px);
    
  }
  .col-3 {
    background: #f2f2f2;
    .menu-box {
      padding: 10px;

      .menu {
        position: sticky;
        top: 80px;
        h2 {
          font-size: clamp(20px, 2vw, 25px);
          padding: 3px;
          background-color: black;
          font-family: "Cormorant Garamond", serif;
          color: #fff;
          margin-bottom: 10px;
        }
        li {
          font-size: clamp(20px, 2vw, 25px);
          font-weight: 600;
          line-height: 1.5;
          padding-left: 10px;
          font-family: "Cormorant Garamond", serif;
        }
      }
    }
  }
  .col-9 {
    min-height: 50vh;
    background: #fbfbfb;
    &.WISHLIST {
      h4.price {
        font-weight: revert-layer;
        font-size: large;
      }
      button.cart-wish-btn {
        font-size: xx-large;
        translate: 0;
      }
      .itemlist-wrap p {
        padding-right: 10%;
        font-size: 12px;
      }
    }
    h2.cart-tit {
      font-family: "Cormorant Garamond", serif;
      text-align: center;
      padding: 10px;
      font-size: clamp(30px, 3.7vw, 35px);
      font-weight: 600;
      &.top {
         background: black;
         color: rgb(255, 255, 255);
         border-radius: 0 0 50px;
         padding: 17px;
         margin-bottom: 20px;
      }
    }
    .item-box {
      
    height: 55vh;
    margin-bottom: 5vh;
      flex-wrap: wrap;
      display: flex;
      .item {
        height: 25vh;
        box-sizing: border-box;
        flex-basis: calc(50% - 2vw);
        width: 80%;
        margin: 1vw 1vw;
        flex-direction: row;
      }
    }
  }
}

.mem-box {
  width: 90%;
  max-width: 500px;
  margin: 2vw auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  label {
   
    padding-bottom: 10px;
    width: 90%;
    flex: 1;
    font-size: 1rem;
    color: #555;
  }
  input {
    width: 85%;
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    &.id {
      background: transparent;
    }
  }
  input:focus {
    border-color: #007bff;
    outline: none;
  }
  input.id:focus {
    border-color: #ccc;
    outline: none;
  }
}

@media screen and (max-width: 900px) {
  .mypage-area .col-9 .item-box .item {
    flex-basis: auto;
    width: auto;
    margin: 1vw 1vw;
  }
}
@media screen and (max-width: 650px) {
  .mypage-area {
    .swiper-wrapper.cont-box {
      flex-direction: row;
  }
  div#bestitem-area {
  
    width: auto;
  }

    .cont-box {
      flex-direction: column;

      .col-3 {
        padding-top: 15px;
        margin: 0 -10px;
        top: 0px;
        position: sticky;
        background: #f2f2f2;
        z-index: 3;
      }
    }
  }
}

span.mypage-tit {
  font-size: clamp(20px, 4vw, 30px);
  font-weight: bold;
  display: block;
  text-align: center;
  padding: 30px;
}

.mypage-link{
  font-size: clamp(15px, 2vw, 20px);
  display: block;
  text-align: center;
  font-weight: 700;
  color: blue;
  cursor: pointer;
}

.col-9.WISHLIST>#bestitem-area {
  
  width: 75vw;
}

@media (max-width: 550px) {
  .mem-box {
    padding: 0px;
    margin-bottom: 30px;
    div {
      margin: 5% auto;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
    span {
      padding-left: 3vw;
      width: 90%;
      margin-bottom: 5px;
    }
  }
}
