/* 게시판 모듈용 CSS - board.css  */

/* CSS Document */

/* 검색옵션박스 */
.select-option-box {
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  max-width: 90%;
  width: 950px;
  margin: 0 auto;

  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  select {
    margin: 0 5px;
  }
  button {
    margin: 0 5px;
  }
}

/*테이블 디자인*/
.data-table {
  /*테이블전체*/
  text-align: center;
  /*텍스트 가로 중앙*/
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: clamp(9px, 1vw, 15px);
  border-collapse: collapse;
  max-width: 90%;
  width: 950px;
  margin: 0 auto;
  thead {
    color: white;
  }
}

.data-table caption,
.data-table-view caption {
  /*테이블 타이틀*/
  font-family: "Cormorant Garamond", serif;
  font-size: clamp(37px, 4vw, 45px);
  font-weight: bold;
  /*글자두께*/
  color: #333;
  padding: 40px 0;
}

.data-table a {
  text-decoration: none;
  color: #666;
}

.btn-group td {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.data-table td,
.data-table th {
  /*여러개를 설정할때 컴마(,) 사용*/
  padding: 10px;
  /*위아래, 양쪽*/
  /*
       padding은 안쪽 공간-> 기존박스가 커짐
       padding: 위 오른 아래 왼;(4개)
       padding: 위 오른왼 아래;(3개)
       padding: 위아래 오른왼;(2개)
       padding: 모든방향;(1개)
    */
}

/* 보드 메인리스트 a요소 말줄임표시하기 */
#board tbody td a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 가로크기 지정(최대361px) */
  width: min(361px, 40vw);
  .update-label {
    font-weight: 600;
    display: inline-block;
    margin-left: 5px;
    padding: 3px 7px;
    background: #007aff;
    border-radius: 10px;
    color: #ffffff;
    animation: update-label-ani 1s infinite;
    &.new {
      background: #ff00bb;
      animation: new-label-ani 1s infinite;
    }
  }
  @keyframes update-label-ani {
    0% {
      background: #007aff;
    } //색변화가 퍼센트에 따라 변해서 자연스럽게 보임
    50% {
      background: #5eb2ff;
    }
    100% {
      background: #007aff;
    }
  }
  @keyframes new-label-ani {
    0% {
      background: #ff00bb;
    } //색변화가 퍼센트에 따라 변해서 자연스럽게 보임
    50% {
      background: #f39cff;
    }
    100% {
      background: #ff00bb;
    }
  }
}

/*각 th별 가로크기지정 */
.data-table th:nth-child(1) {
  width: 5%;
}
.data-table th:nth-child(2) {
  width: 40%;
}
.data-table th:nth-child(3) {
  width: 30%;
}
.data-table th:nth-child(4) {
  width: 15%;
}
.data-table th:nth-child(5) {
  width: 5%;
}

.data-table tbody tr td {
  /*본문 td 밑줄*/
  border-bottom: 1px solid #ccc;
  /*두종색*/
}

.data-table thead tr th {
  background-color: black;
  /*머리줄 th 윗줄*/
  // border-top: 2px solid #999;
  /*두종색*/
  // border-bottom: 1px solid #999;
}

.data-table tbody tr:last-child td {
  /*테이블본문의 마지막 줄 tr 의 td 밑줄변경*/
  // border-bottom: 2px solid #999;
}
.data-table tbody tr td:nth-child(2) {
  text-align: left;
}

/*
    가상 클래스 중
    :first-child - 첫번째 자식
    :last-child - 두번째 자식
    :nth-child(숫자) - 숫자번째 자식 (짝수 even, 홀수 odd 사용)
    단, 자식 태그의 종류가 한가지 여야함~!!!!
 */
/*짝수번째 배경색 넣기*/
.data-table tbody tr:nth-child(even) td {
  /*even 짝수, odd 홀수*/
  // background-color: #f3f3f3;
}

/*하단 패딩*/
/* tfoot의 td에 padding 및 글자중앙 적용하기 */
.data-table tfoot td {
  text-align: center;
  /*글자중앙정렬*/
  padding: 20px 0px;
  /*위아래,우좌 padding설정*/
}

/* 내용보기 */
.data-table-view {
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 20px;
  font-weight: 500;
  width: 707px;
  margin: 0px auto;
  // border-top: 2px solid #999;
  /*두종색*/
  // border-bottom: 2px solid #999;
  input {
    border: none;
  }
  textarea.content {
    margin-top: 10px;
    border: none;
  }
  span.beforecont {
    font-size: 11px;
    color: #767676;
    text-decoration: line-through;
  }
}
/*첫째열*/
.data-table-view td:first-child {
  width: 100px;
}
/*둘째열*/
.data-table-view td:last-child {
  width: 650px;
}

.data-table-view td {
  border-bottom: 1px solid #ccc;
  padding: 8px;

  span.modify-data {
    color: #000000;
    font-weight: 600;
    padding: 0 5px;
    font-size: 13px;
  }
}

.data-table-view td:first-child {
  color: white;
  font-size: 14px;
  background: black;
  vertical-align: middle;
  width: 200px;
  // background-color: #e0e0e0;
  // text-align: center;
}

/* 추가 첨부파일링크 */
.data-table-view td a,
.data-table-view td b {
  font-size: 20px;
  text-decoration: none;
  color: #000;
}
.data-table-view td a:hover {
  text-decoration: underline;
  color: #f00;
}

/* 버튼그룹 */
.btn-group button {
  padding: 8px 40px;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 15px;
  transition: 0.3s;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border: 5px double #000000a1;
  margin: 5px;
  &.list {
    border: 5px double rgb(255 255 255);
    color: white;
    background: black;
    margin-left: auto;
  }
}

@media screen and (max-width: 700px) {
  /* 보기 모드 공통 */
  .cont .data-table-view {
    width: 90vw;
  }
  .cont .data-table-view td {
    width: auto;
    font-size: 16px;
  }
  .cont .data-table-view input,
  .cont .data-table-view textarea {
    width: calc(90vw - 64px);
  }

  .select-option-box {
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    /* max-width: 90%; */
    /* width: 950px; */
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 550px) {
  .btn-group button {
    flex: 1;
    flex-basis: 50%;
  }
}

@media screen and (max-width: 360px) {
  input#search-text {
    width: 120px;
  }

  .btn-group button {
    flex: 1;
    flex-basis: none;
  }
}
